import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Header from '../components/Header'
import styled from 'styled-components';
import ProductForm from '../components/ProductForm';
import ProductGallery from '../components/ProductGallery';
import ProductDetails from '../components/ProductDetails';
import { Footer } from '../components/Footer';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`;


function Home() {
    function getCurrency(str) {
        return str.match(/[A-Z]{3}/)?.[0];
    }
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [responseData, setResponseData] = useState(null);
    // config env

    const API_URL = process.env.REACT_APP_BACKEND_API_URL;

    const addFetchProduct = async (url, website) => {
        let data = JSON.stringify({
            "url": url
        });
        let config = {};
        if (website === "noon") {
            config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${API_URL}/ScrapeNoon`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
        } else if (website === "amazon") {
            config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${API_URL}/ScrapeAmazon`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
        } else if (website === "sharafdg") {
            config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${API_URL}/ScrapeSharafDG`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
        } else if (website === "jumbo") {
            config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${API_URL}/ScrapeJumbo`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
        }

        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                console.log("Success!")
                GetProducts();
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const GetProducts = async () => {
        const response = await axios.get(`${API_URL}/AllProducts`);
        console.log(response.data);
        setProducts(response.data);
    }

    useEffect(() => {
        GetProducts();
    }, []);

    const selectProduct = (product) => {
        setSelectedProduct(product);
    };
    return (
        <>
            {/* <Header /> */}
            <Container>
                <ProductForm onAddProduct={addFetchProduct} />
                <ProductGallery products={products} onSelectProduct={selectProduct} />
                {/* <ProductDetails product={selectedProduct} /> */}
            </Container>
            {/* <Footer /> */}
        </>
    )
}

export default Home