import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, CircularProgress, Select, MenuItem, InputLabel, FormControl } from '@mui/material';

const ProductForm = ({ onAddProduct }) => {
    const [url, setUrl] = useState('');
    const [website, setWebsite] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(website)
        if (!website) {
            alert("Please select a website to track the product from.");
            return;
        }
        onAddProduct(url, website);
        setUrl('');
        setLoading(true);
    };


    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let timer;
        if (loading) {
            timer = setTimeout(() => {
                setLoading(false);
                // scroll down
                window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: 'smooth'
                });
            }, 4000);
        }

        // Clean up the timer on component unmount or if loading changes
        return () => clearTimeout(timer);
    }, [loading]);

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                width: '100%',
                maxWidth: '800px',
                margin: '0 auto'
            }}
        >
            <TextField
                label="Enter Product URL"
                variant="outlined"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                fullWidth
            />
            {/* Drop down to choose amazon or noon */}
            <FormControl>

                <InputLabel id="demo-simple-select-label">Website</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={website}
                    label="Website"
                    placeholder='Select'
                    style={{ width: '150px', marginRight: 90 }}
                    onChange={(e) => setWebsite(e.target.value)}
                >
                    <MenuItem value={'noon'}>Noon</MenuItem>
                    <MenuItem value={'amazon'}>Amazon</MenuItem>
                    <MenuItem value={'sharafdg'}>SharafDG</MenuItem>
                    <MenuItem value={'jumbo'}>Jumbo</MenuItem>
                </Select>
            </FormControl>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                    padding: '10px 0',
                    width: '200px'
                }}
            >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Track'}
            </Button>
            {/* Add 5 seconds loading after clicking Track */}

        </Box>
    );
};

export default ProductForm;
