import React, { useEffect, useState } from 'react';
import './ProductGallery.css'; // Make sure to create this CSS file
import { useNavigate } from 'react-router-dom';
import { Box, TextField, InputAdornment, IconButton, Menu, MenuItem, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';

const ProductGallery = ({ products, onSelectProduct }) => {
    function TruncateTitle(title) {
        if (title.length > 20) {
            title = title.split(' ').slice(0, 9).join(' ')
        }
        return title
    }
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState(products);
    const navigate = useNavigate();
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState('');

    const handleClear = () => {
        setSearchTerm('');
    };

    const handleFilterClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setFilterAnchorEl(null);
    };

    const handleFilterSelect = (filter) => {
        setSelectedFilter(filter);
        handleFilterClose();
    };


    useEffect(() => {
        var tempFiltered;
        if (searchTerm) {
            const filteredProducts = products.filter(product => product.title.toLowerCase().includes(searchTerm.toLowerCase()));
            tempFiltered = filteredProducts;
        } else {
            tempFiltered = products;
        }

        var tempFiltered2;
        if (selectedFilter) {
            const filteredProducts2 = tempFiltered.filter(product => product.source.toLowerCase().includes(selectedFilter.toLowerCase()));
            tempFiltered2 = filteredProducts2;
        } else {
            tempFiltered2 = tempFiltered;
        }

        setFilteredProducts(tempFiltered2);

    }, [searchTerm, products, selectedFilter]);

    return (
        <>
            <Box
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    width: '100%',
                    maxWidth: '800px',
                    margin: '20px auto'
                }}
            >
                <TextField
                    label="Search Products"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: searchTerm && (
                            <InputAdornment position="end">
                                <IconButton onClick={handleClear}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Button
                    variant="outlined"
                    startIcon={<FilterListIcon />}
                    onClick={handleFilterClick}
                    style={{ width: '200px' }}
                >
                    Filter Source
                </Button>
                <Menu
                    anchorEl={filterAnchorEl}
                    open={Boolean(filterAnchorEl)}
                    onClose={handleFilterClose}
                    sx={{
                        '& .MuiPaper-root': {
                            width: '162px', // Adjust this value to increase the width
                        }
                    }}
                >
                    <MenuItem onClick={() => handleFilterSelect('')}>All</MenuItem>
                    <MenuItem onClick={() => handleFilterSelect('Noon')}>Noon</MenuItem>
                    <MenuItem onClick={() => handleFilterSelect('SharafDG')}>SharafDG</MenuItem>
                    <MenuItem onClick={() => handleFilterSelect('Jumbo')}>Jumbo</MenuItem>
                    <MenuItem onClick={() => handleFilterSelect('Amazon')}>Amazon</MenuItem>
                </Menu>
            </Box >

            <div className="product-gallery">
                {filteredProducts.map((product, index) => (
                    <div key={index} className="product-card" onClick={() => navigate(`/products/${product.productID}`)}>
                        <img src={product.image} alt={product.title} className="product-image" />
                        {/* {console.log(product)} */}
                        {product.currentPrice < product.originalPrice && <div className="product-badge"></div>}
                        <div className="product-discount-badge">Discount: {product.discountPercentage}%</div>
                        <h3 className="product-title">{TruncateTitle(product.title)}</h3>
                        {product.currentPrice < product.originalPrice ? <div className="product-price">Price: <div className="product-originalPrice">{product.currency} {product.originalPrice}</div> <span className='product-price-text'>{product.currency} {product.currentPrice}</span> </div> : <div className="product-price">Price: <div className="product-price-text">{product.currency} {product.originalPrice}</div></div>}
                        {/* <div className="product-discount">Discount: {product.discountPercentage}%</div> */}
                        <div className={product.source == "noon" ? 'shopnow-btn shopnow-noon' : 'shopnow-btn shopnow-amazon'}>
                            <a href={product.url} target="_blank" rel="noreferrer">Shop Now</a>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default ProductGallery;
