import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import ProductPriceHistory from '../components/ProductPriceHistory';
import EmailTrackForm from '../components/EmailTrackForm';

const Products = () => {

    const { productid } = useParams();
    const [product, setProduct] = useState(null);

    function GetData() {
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/Product/${productid}`)
            .then(response => {
                console.log("FETCH ONE", response.data);
                setProduct(response.data);
            });
    }
    useEffect(() => {
        GetData();
    }, []);


    function formatDate(date) {
        const d = new Date(date);
        // convert to dd/mm/yyyy hh:mm am/pm
        return d.toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
    }

    return (
        <div>
            {/* <h2>Product Detail Page</h2>
            <p>Product ID: {productid}</p> */}
            <div style={{
                maxWidth: "fit-content",
                marginLeft: "auto", marginRight: "auto"
            }}>
                {product && <div className="product-card-full">
                    <img src={product.image} alt={product.title} className="product-image" />
                    {/* {console.log(product)} */}
                    {product.currentPrice < product.originalPrice && <div className="product-badge"></div>}
                    <div className="product-discount-badge">Discount: {product.discountPercentage}%</div>
                    <h3 className="product-title">{product.title}</h3>
                    {product.currentPrice < product.originalPrice ? <div className="product-price">Price: <div className="product-originalPrice">{product.currency} {product.originalPrice}</div> <span className='product-price-text'>{product.currency} {product.currentPrice}</span> </div> : <div className="product-price">Price: <div className="product-price-text">{product.currency} {product.originalPrice}</div></div>}
                    {/* <div className="product-discount">Discount: {product.discountPercentage}%</div> */}
                    <div className='shopnow-btn-full'>
                        <a href={product.url} target="_blank" rel="noreferrer">Shop Now</a>
                    </div>
                    {/* show other fields in objectdata variable */}
                    <div className="additional-info">
                        <div className="info-item"><span className="bold-text">Source:</span> {capitalizeFirstLetter(product.source)}</div>
                        <div className="info-item"><span className="bold-text">Average Price:</span> {product.currency} {product.averagePrice}</div>
                        <div className="info-item"><span className="bold-text">Highest Price:</span> {product.currency} {product.highestPrice}</div>
                        <div className="info-item"><span className="bold-text">Lowest Price:</span> {product.currency} {product.lowestPrice}</div>
                        <div className="info-item"><span className="bold-text">Product ID:</span> {product.productID}</div>
                        <div className="info-item"><span className="bold-text">Created At:</span> {formatDate(product.createdAt)}</div>
                        <div className="info-item"><span className="bold-text">Updated At:</span> {formatDate(product.updatedAt)}</div>
                    </div>
                    <ProductPriceHistory priceHistory={product.priceHistory} />
                    <EmailTrackForm productID={productid} />
                </div>}
            </div>
        </div >
    )
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default Products