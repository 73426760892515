import React from 'react';
import styled from 'styled-components';
import './Header.css'

const Header = () => {
    return (
        <header className='headerContainer'>
            <a href="/" className='headerLogo'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img className="headerLogo" src="/Price Patrol Logo White.png" alt="Logo" />
                    {/* <h1 className='title'>Price Tracker</h1> */}
                </div>
            </a>
            {/* <nav className='Nav'>
                <a href="#" className='NavLink'>Home</a>
                <a href="#" className='NavLink'>Products</a>
                <a href="#" className='NavLink'>About</a>
                <a href="#" className='NavLink'>Contact</a>
            </nav> */}
        </header>
    );
};

export default Header;
