import React from 'react';
import './Footer.css';

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    {/* Left Column */}
                    <div className="col-md-6 col-lg-4">
                        <div className="widget1">
                            <div className="logo">
                                <img src="/Price Patrol Logo White.png" className="img-fluid" alt="" />
                            </div>
                            <p>
                                Price Patrol is a powerful and intuitive price tracking website designed to help you make informed purchasing decisions effortlessly. Whether you're a savvy shopper, a deal hunter, or a business professional monitoring market trends, Price Patrol empowers you with real-time price updates and insightful analytics.
                            </p>
                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="col-md-6 col-lg-4 ml-auto"> {/* ml-auto pushes it to the right */}
                        <div className="widget4">
                            <h5>
                                Links
                            </h5>
                            <ul>
                                <li>
                                    <a href="/">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        Products
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* Copyright Area */}
            <div className="copyRightArea">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <p>Price Patrol &copy; Copyright 2024.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
