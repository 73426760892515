import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import './ProductPriceHistory.css';

const ProductPriceHistory = ({ priceHistory }) => {
    // Preprocess data to get one entry per day
    const uniqueDates = {};
    priceHistory.forEach(entry => {
        try {
            const date = new Date(entry.date);
            const dateString = date.toISOString().split('T')[0]; // Extract only the date part
            // console.log(dateString)
            if (!uniqueDates[dateString]) {
                uniqueDates[dateString] = entry.price;
            }
        } catch (error) {
            console.error('Invalid date format:', entry.date, error);
        }
    });

    // Ensure each day has a price, filling in with the previous day's price if missing
    const dates = Object.keys(uniqueDates).sort((a, b) => new Date(a) - new Date(b));
    for (let i = 1; i < dates.length; i++) {
        if (!uniqueDates[dates[i]]) {
            uniqueDates[dates[i]] = uniqueDates[dates[i - 1]];
        }
    }

    // Prepare data for the chart
    const labels = Object.keys(uniqueDates);
    const data = Object.values(uniqueDates);

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Daily Price',
                data: data,
                fill: false,
                backgroundColor: '#FF6384',
                borderColor: '#FF6384',
                borderWidth: 2,
                pointRadius: 4,
                pointBackgroundColor: '#FF6384',
                pointBorderColor: '#fff',
                pointHoverRadius: 6,
                pointHoverBackgroundColor: '#FF6384',
                pointHoverBorderColor: '#fff',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: function (tooltipItems) {
                        const date = tooltipItems[0].label;
                        // extract only date and not time
                        return date.split(',')[0] + ',' + date.split(',')[1];
                    },
                    label: function (tooltipItem) {
                        return `Price: ${tooltipItem.raw}`;
                    }
                },
                backgroundColor: 'rgba(0,0,0,0.8)',
                titleFont: {
                    size: 16,
                },
                bodyFont: {
                    size: 14,
                },
                cornerRadius: 4,
                xPadding: 10,
                yPadding: 10,
            },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                },
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: 12,
                    },
                },
            },
            y: {
                grid: {
                    color: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                    font: {
                        size: 12,
                    },
                },
            },
        },
    };

    return (
        <div className="chart-container">
            <h2 className='PriceHistory-heading'>Product Price History</h2>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default ProductPriceHistory;
