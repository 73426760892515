import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, CircularProgress } from '@mui/material';
import axios from 'axios';

const EmailTrackForm = ({ productID }) => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(productID);
        // console.log(email);
        addEmail(email);
        setEmail('');
        setLoading(true);
    };

    const addEmail = async (email) => {
        let data = JSON.stringify({
            "email": email
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BACKEND_API_URL}/AddUserEmail/${productID}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log("Success!")
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let timer;
        if (loading) {
            timer = setTimeout(() => {
                setLoading(false);
                // // scroll down
                // window.scrollTo({
                //     top: document.body.scrollHeight,
                //     behavior: 'smooth'
                // });
            }, 1000);
        }

        // Clean up the timer on component unmount or if loading changes
        return () => clearTimeout(timer);
    }, [loading]);

    return (
        <>
            {/* Add call to action to ask users to fill up to track email */}
            <h2 className='signup-track-h2'>
                Sign up below to get notified when the price drops!
            </h2>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    width: '100%',
                    maxWidth: '800px',
                    margin: '0 auto'
                }}
            >
                <TextField
                    label="Enter Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                        padding: '10px 0',
                        width: '200px'
                    }}
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Add Email'}
                </Button>
                {/* Add 5 seconds loading after clicking Track */}

            </Box>
        </>
    );
};

export default EmailTrackForm;
